
html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Virgil;
  }

@font-face {
    font-family: 'Virgil';
    src: url('../public/fonts/Virgil.woff2') format('woff2') /* Super Modern Browsers */
}




 