:root {
  --primary-color: #302AE6;
  --secondary-color: #536390;
  --pressed-color: #dfe1e6;
  --font-color: #141313;
  --bg-color: #fff;
  --heading-color: #292922;
  --link-color: rgb(65, 54, 129);
  --handle-color: rgba(0, 0, 0, 0.151);
}

[data-theme="dark"] {
  --primary-color: #9A97F3;
  --secondary-color: #818cab;
  --pressed-color: #5e677e;
  --font-color: #fff3f3;
  --bg-color: #161625;
  --heading-color: #818cab;
  --link-color: #76912b;
  --handle-color: rgba(255, 255, 255, 0.075);
}

body {
  background: var(--bg-color);
  color: var(--font-color);
  overflow-x: hidden;
  font-family: 'Ubuntu'
}

a {
  color: var(--link-color);
  text-decoration: none;
  white-space: "nowrap";

}

.react-flow {
  background: var(--bg-color);
}

.info {
  height: 33px;
  width: 33px;
  position: relative;
  top: 4px;
  left: 4px;
}

svg.info path {
  fill: var(--font-color)
}


.back {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
}



.close-button {
  display:none;
  font-size: 1.5em;
  height: 33px;
  width: 33px;
  border: 2px solid var(--font-color);
  border-radius: 12px;
  background-color: transparent;
  color: var(--font-color);
  transition: all 0.2s;
  z-index: 4;
  margin: 5px;
}

.close-button:hover {
  background-color: rgb(193, 193, 207);
  transition: 250ms;
}


.save__controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.save__controls button {
  margin-left: 5px;
}



path.react-flow__edge-path {
  stroke-width: 0.7;
  stroke: var(--font-color);
}

.react-flow__edge {
  cursor: grab
}

.editContainer {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 4;
    height: 200px;
    width: 250px;
    
  }


.my-react-select-container .my-react-select__placeholder {
  color: var(--font-color);
}

.my-react-select-container .my-react-select__control {
  border: 2px solid var(--font-color);
  background-color: var(--bg-color);
  cursor: pointer;
}

.my-react-select-container .my-react-select__control:hover {
  border: 2px solid var(--secondary-color);
  transition: 250ms;
}


.my-react-select-container .my-react-select__dropdown-indicator {
  color: var(--font-color);  
  transition: 0.0s;

}

.my-react-select-container .my-react-select__indicator-separator {
  background-color: var(--font-color);
  width: 2px;
}

.my-react-select-container .my-react-select__single-value {
  color: var(--font-color);
  transition: 0.0s;
}

.my-react-select-container .my-react-select__menu {
  color: var(--font-color);
  background-color: var(--bg-color);
  border: 2px solid var(--font-color);
  margin-left: 6px;
  border-radius: 6px;
}

.my-react-select-container .my-react-select__menuList {
  background-color: blue;
}

.my-react-select-container .my-react-select__option {
  background-color: var(--bg-color);
  color: var(--font-color);
  cursor: pointer;
  margin: 0px;
}


.my-react-select-container .my-react-select__option:hover {
  color: var(--font-color);
  background-color: var(--pressed-color);
}

.my-react-select-container .my-react-select__option:active {
  color: var(--link-color);

}


.react-flow__attribution {
  background-color: var(--bg-color);
  color: var(--font-color);
  z-index: 0;
}




.link {
  color: var(--link-color);
  text-decoration: none;
  white-space: nowrap
}


@media (max-width: 740px) {

.lightMode {
    position: absolute;
    z-index: 20;
    right: 8px;
    top: 8px;
    }


  .info-window {
    position: absolute;
    height: 75%;
    top: 75px;
    left: 10px;
    right: 10px;
    background-color: white;
    border: 1px solid black;
    z-index: 5;
    padding: 5px;
  }

  .info-content {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }

  .info-text {
    padding: 5px;
  }

  .info-image {
    padding: 5px;
  }

  
  .info-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .info-button {
    position: absolute;
    display: flex;
    bottom: 14px;
    right: 70px;
    border: 2px solid var(--font-color);
    border-radius: 12px;
    box-sizing: border-box;
    background-color: transparent;
    color:#000000;
    text-align:center;
    z-index: 4;
    background-color: var(--bg-color);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 20px 0px;
  }

  .about {
    position: absolute;
    bottom: 5px;
    left: 170px;
    box-shadow: 0 5px 20px 0 hsla(240,5%,41%,.2);
  }


  .scrollbar {
    position: absolute;
    width: 60%;
    right: 8px;
    bottom: 18px;
    z-index: 4;
  }

  .component {   
    display: flex;
    justify-content: flex-start;
    z-index: 9999;
    font-size: 35px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .component-2 {
    z-index: 4;
    display: flex;
    font-size: 15px;
    position:static
  }

  .superscript {
    margin: 3px;
    vertical-align: super;
    font-size: 4px;
  }
}

@media (min-width: 740px) {

  .node-window {
    position: absolute;
    top: 67px;
    right: 15px;
    width: 375px;
    background-color: var(--bg-color);
    border: 2px solid var(--font-color);
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 20px 0px;
    border-radius: 12px;
    z-index: 999;
  
  }

  .node-window-bar {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
  }

  .large-text {
    font-size: 18px;
    margin-right: 10px;
  }

  .line-4 hr {
    height: 2px;
    border: 0;
    background: var(--font-color);
    margin-top: 0px
  }


  .info-window {
    position: absolute;
    overflow: auto;
    height: 75%;
    top: 75px;
    left: 5%;
    right: 5%;
    background-color: white;
    border: 1.5px solid black;
    border-radius:0.4em;
    z-index: 5;
    padding: 20px;    
  }
  
  .info-content {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 10px
  }
  
  .info-text {
    width: 30%;
    padding: 10px;
    top: 5px;
    position: relative;
  }
  
  .info-image {
    width: 70%;
    padding: 10px;
  }
  
  .info-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }


  .container {
    display: flex;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }


  .component {   
    flex: 1.3;
    display: flex;
    justify-content: flex-start;
    font-size: 50px;
  }

  .scrollbar {
    margin: auto;
    align-items: center;
    z-index: 4;
    width: 260px;
  }

  .about {
    display: flex;
    align-items: center;
    margin-left: 8px;
    z-index: 20;
  }

  .lightMode {
    display: flex;
    align-items: center;
    z-index: 20;
  }

  .info-button {
    display: flex;
    align-items: center;
    height: 34px;
    font-size: 17px;
    color: var(--font-color);
    border:2px solid black;
    border-color: var(--font-color);
    background-color: transparent;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    z-index: 20;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 20px 0px;
    text-decoration: none;
    background-color: var(--bg-color);
    transition: 0.0s;
  }

  .info-button:hover {
    border-color: var(--secondary-color);
    transition: 250ms;
  }

  .superscript {
    margin: 3px;
    vertical-align: super;
    font-size: 10px;
  }

}

.updatenode__controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.updatenode__controls label {
  display: block;
}

.updatenode__bglabel {
  margin-top: 10px;
}

.updatenode__checkboxwrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
}


.updatenode__controls {
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 4;
  font-size: 12px;
}


.updatenode__controls label {
  display: block;
}

.updatenode__bglabel {
  margin-top: 10px;
}

.updatenode__checkboxwrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
}


.react-flow .react-flow__handle {
  width: 7px;
  height: 7px;
  background-color: transparent;
  border-color: var(--handle-color);
  visibility: visible;
}



.react-flow .react-flow__handle-top {
  top: -7px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -7px;
}

.react-flow .react-flow__handle-left {
  left: -13px;
}

.react-flow .react-flow__handle-right {
  right: -13px;
}

.react-flow .react-flow__controls-button {
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--font-color);
  fill: var(--font-color)
}

.react-flow .react-flow__controls-button:hover {
  background-color: var(--secondary-color);
  fill: var(--font-color);
  transition: 250ms;
}

.react-flow  .react-flow__controls {
  border-top: 1px solid var(--font-color);
  border-left: 1px solid var(--font-color);
  border-right: 1px solid var(--font-color);
  border-radius: 3px
}


.largeInput {
  height: 100px;
}



